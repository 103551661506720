
  import { Component, Watch } from 'vue-property-decorator'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import LinkedAuto from '@/components/forms/fields/LinkedAuto.vue'
  import { plainToInstance } from 'class-transformer'
  import { Form } from '@/entities/public/Resource/metadata'
  import { Lead } from '@/entities/crm'
  import InitialFields from '@/components/forms/fields/InitialFields.vue'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import StockPrices from '@/components/toolkit/details/row/custom/StockPrices.vue'
  import AutoHeader from '@/components/toolkit/details/row/expandable/auto/header.vue'
  import Simple from '@/components/toolkit/details/row/simple.vue'
  import { Negotiation, PurchaseOrder } from '@/entities/purchase'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { AcquisitionType, Expense, Process, ProcessExpense } from '@/entities/settings'
  import { fixPrice, isValidNumber, nameAlert, parseToNumber, stringifySafe, updateNestedObject } from '@/utils/general'
  import { PurchaseView } from '@/components/forms/view/PurchaseView'
  import { Payment, PaymentOrder, PaymentOrderItem, PaymentRecipient, PaymentType } from '@/entities/finance'
  import { Document } from '@/entities/documents'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import GLoading from '@/components/core/GLoading.vue'
  import logOut from '@/components/LogOut.vue'
  import { AutoAttribute } from '@/entities/vehicle'
  import GCostField from '@/components/core/input/GCostField.vue'
  import dayjs from 'dayjs'
  import AutoResume from '@/components/forms/AutoResume.vue'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'

@Component({
  components: {
    GRadioButton,
    AutoResume,
    GCostField,
    logOut,
    GLoading,
    GAlert,
    GFiles,
    Simple,
    StockPrices,
    FieldTitle,
    LinkedPerson,
    InitialFields,
    LinkedAuto,
    BaseForm,
    AutoHeader,
  },
})
  export default class PurchaseForm extends PurchaseView {
  payments: Payment[] = []
  negotiation = plainToInstance(Negotiation, {})
  lead = plainToInstance(Lead, {})
  autoAttribute = plainToInstance(AutoAttribute, {})
  purchaseOrder = plainToInstance(PurchaseOrder, {})
  process: Process = plainToInstance(Process, {})
  paymentOrder: PaymentOrder[] = []
  dealAutoAttribute = null
  stock = null
  deal = null
  stockView = null
  document: Document = plainToInstance(Document, {})
  existConsignment: Document = plainToInstance(Document, {})
  consignmentHasDocument = []
  acquisitionTypes: AcquisitionType[] = []
  backupDocumentType = []
  signerError = ''
  ownerError = ''
  clientError = ''
  priceErrorSend = ''
  displayConsignment = false
  purchase = {
    open: false,
    title: '',
    item: null,
  }

  purchaseMetadata = null
  title = ''
  idProcess = null
  idProcessDocument = null
  showDetail = false
  fileInfo = null
  panels = [0]
  section = {
    interviews: false,
    documents: false,
    payment: false,
  }

  declare $refs: {
    form: HTMLFormElement
    fieldSigners: LinkedPerson
    fieldClient: LinkedPerson
  };

  metadata = {}
  formData = {
    whoSigns: null,
    consignment: [],
    price: null,
    client: null,
    owners: null,
    signers: null,
    buyFile: null,
    expenses: null,
    legalReport: null,
    documentType: null,
    agreedTransfer: null,
    linkContract: null,
    linkLegalReport: null,
    responsibleLetter: null,
    notarizedAuthorization: null,
    lastOrder: null,
    acquisitionType: null,
  }

  fields = {
    consignment: {
      properties: {
        accept: '',
        fileTypeId: null,
        multiple: false,
        name: '',
      },
      rules: this.fieldRequired,
    },
    price: {
      properties: {},
      rules: this.fieldRequired,
    },
    client: {
      properties: {},
      items: [],
      rules: [],
    },
    owners: {
      properties: {},
      items: [],
      rules: [],
    },
    signers: {
      properties: {},
      items: [],
      rules: [],
    },
    buyFile: {
      properties: {
        accept: '',
        fileTypeId: null,
        multiple: false,
        name: '',
      },
      rules: this.fieldRequired,
    },
    legalReport: {
      properties: {
        accept: '',
        fileTypeId: null,
        multiple: false,
        name: '',
      },
      rules: this.fieldRequired,
    },
    notarizedAuthorization: {
      properties: {
        accept: '',
        fileTypeId: null,
        multiple: false,
        name: '',
      },
      rules: this.fieldRequired,
    },
    documentType: {
      properties: {},
      rules: this.fieldRequired,
      items: [],
    },
    agreedTransfer: {
      properties: {},
      rules: this.fieldRequired,
      items: [],
    },
    linkLegalReport: {
      properties: {},
      rules: this.fieldRequired,
    },
    linkContract: {
      properties: {},
      rules: null,
    },
    responsibleLetter: {
      properties: {},
      rules: this.fieldRequired,
    },
    expenses: {
      properties: {},
      items: [],
      rules: this.fieldRequired,
    },
  }

  negotiationAlert = {
    flag: false,
    title: '',
    subtitle: '',
  }

  openConsignmentAlert = {
    flag: false,
    title: 'Al renovar la consigna anulará la consigna anterior',
    subtitle: '¿Estás seguro que deseas continuar?',
  }

  confirmPaymentAnulled = {
    flag: false,
    title: 'Esta acción no podrá ser revertida',
    subtitle: '¿Estás seguro que deseas continuar?',
  }

  async mounted () {
    this.metadata = {}
    this.loadingForm = true
    await this.setMetadata()

    const { id, uid, model, title, purchaseMetadata, parentId } = this
    await this.getListValues()

    if ((!isNaN(uid) && (['PurchaseOrder', 'Consignment'].includes(model) || model === '')) || !isNaN(id)) {
      const purchaseId = id || uid
      await this.getPurchaseInfo(purchaseId)
    }
    if (!isNaN(parentId)) {
      await this.getNegotiationInfo(parseToNumber(parentId))
    }

    if (!this.isBreadCrumbPresent(title)) {
      const dynamicTitle = this.purchaseIsConsignment ? 'Consignación' : 'Compra'
      this.title = dynamicTitle
      this.setFormCrumbs(purchaseMetadata, dynamicTitle, Boolean(this.purchaseOrder?.id))
    }
    if (this.purchaseOrder?.id) {
      this.paymentOrder = await this.fetchData({
        query: { name: 'find', model: 'PaymentOrder' },
        filter: { id_process_record: { _eq: this.purchaseOrder.id } },
        force: true,
      })

      this.payments = await this.fetchData({
        query: { name: 'find', model: 'Payment' },
        filter: { closing_reason: { type: { name: { _eq: 'canceled' } } } },
        force: true,
      })

      this.formData.expenses = this.paymentOrder?.[0]
      this.purchaseOrder.paymentOrder = this.paymentOrder[0]
    }

    this.setTheBackup()
    await this.setDetails()
    this.loadingForm = false
  }

  async getListValues () {
    this.acquisitionTypes = await this.fetchData({
      query: { name: 'find', model: 'AcquisitionType' },
      filter: { active: { _eq: true } },
    })

    if (this.acquisitionTypes.length === 1 || !this.displayConsignment) {
      this.formData.acquisitionType = this.acquisitionTypes[0]
    }
  }

  get isConsignmentSelected () {
    const { formData } = this
    if (formData?.acquisitionType?.name === 'consignment') {
      this.fields.documentType.properties.required = false
      this.fields.documentType.properties.rules = []
    }
    return formData.acquisitionType?.name === 'consignment'
  }

  setTheBackup () {
    const { backup } = this
    if (!backup) return
    const purchaseForm = 'purchaseForm' in backup && backup.purchaseForm
    const representative = 'signer' in backup && backup.signer

    if (purchaseForm) {
      this.setBackupData(backup)
      this.panels = backup.purchaseForm.panels
    }

    if (representative) {
      const signerNotExist = !this.formData.signers?.find(signer => signer.id === representative.id)
      if (signerNotExist) {
        if (this.formData.signers?.length) {
          this.formData.signers = [...this.formData.signers, representative]
        } else {
          this.formData.signers = [representative]
        }
        this.fields.signers.items.push(representative)
      }
    }

    this.setExpense(backup)

    this.purchaseOrder.paymentOrder = this.formData.expenses
  }

  setSigners (signers) {
    if (!signers?.length) return
    this.formData.signers = signers
    this.fields.signers.items = signers
  }

  setBackupData (backup) {
    this.setSigners(backup.purchaseForm.signers)

    if (isValidNumber(backup.purchaseForm.price)) {
      this.formData.price = backup?.purchaseForm?.price
    }
    const fields = ['acquisitionType', 'documentType', 'agreedTransfer', 'responsibleLetter', 'notarizedAuthorization']

    fields.forEach(field => {
      if (!this?.formData?.[field]) {
        this.$set(this.formData, field, backup.purchaseForm?.[field])
      }
    })

    if (!this.formData?.consignment?.length) {
      this.formData.consignment = backup.purchaseForm.consignment
    }

    if (!this?.formData?.buyFile?.length) {
      this.formData.buyFile = backup.purchaseForm.buyFile || []
    }

    if (!this.formData?.expenses?.length && !this.purchaseOrder?.id) {
      this.formData.expenses = backup.purchaseForm.expenses
    }

    if (!this?.formData?.legalReport?.length) {
      this.formData.legalReport = backup.purchaseForm.legalReport
      this.formData.linkLegalReport = backup.purchaseForm.linkLegalReport
    }

    if (!this?.formData?.linkContract?.length) {
      this.formData.linkContract = backup.purchaseForm.linkContract
    }

    if (backup.purchaseForm.lastOrder?.items.length) {
      this.formData.lastOrder = backup.purchaseForm.lastOrder
    }
  }

  setValueExpense (isExpenseForm, isPurchaseForm) {
    if (!isExpenseForm?.expense && isPurchaseForm?.expenses) {
      this.formData.lastOrder = isPurchaseForm?.expenses
      this.formData.expenses = isPurchaseForm?.expenses
      return true
    }

    if (!isExpenseForm?.expense && !isPurchaseForm?.expenses) {
      return true
    }
  }

  setInstancePayment (isExpenseForm) {
    return plainToInstance(Payment, {
      amount: isExpenseForm?.expense,
      comment: isExpenseForm?.comment,
      paymentBackup: isExpenseForm?.file,
      type: plainToInstance(PaymentType, {
        id: isExpenseForm?.paymentType?.id,
        name: isExpenseForm?.paymentType?.name,
        description: isExpenseForm?.paymentType?.description,
      }),
    })
  }

  setExpense (backup) {
    const isExpenseForm = 'expenseForm' in backup && backup.expenseForm
    const isPurchaseForm = 'purchaseForm' in backup && backup.purchaseForm

    if (isExpenseForm?.payment?.id) return

    if (this.setValueExpense(isExpenseForm, isPurchaseForm)) return

    const recipient = plainToInstance(PaymentRecipient, {})
    recipient.person = isExpenseForm?.beneficiary
    const payment = this.setInstancePayment(isExpenseForm)
    payment.recipientAccount = isExpenseForm?.account
    recipient.payments = [payment]
    if (!isPurchaseForm?.expenses) {
      const order = plainToInstance(PaymentOrder, {
        items: [plainToInstance(PaymentOrderItem, {
          amount: parseToNumber(this.formData.price),
          processExpense: plainToInstance(ProcessExpense, {
            expense: plainToInstance(Expense, {
              description: isExpenseForm.expenseType.description,
              name: isExpenseForm.expenseType.name,
            }),
          }),
        })],
      })

      order.items[0].recipients = [recipient]
      this.formData.lastOrder = order
      this.formData.expenses = order
    } else {
      const lastOrder = isPurchaseForm?.expenses
      const expenseType = isExpenseForm?.expenseType

      const pos = lastOrder?.items?.findIndex(item => item.processExpense.expense.name === expenseType.name)
      if (pos > 0) {
        lastOrder.items[pos].recipients.push(recipient)
      } else {
        const orderItem = plainToInstance(PaymentOrderItem, {
          amount: parseToNumber(this.formData.price),
          processExpense: plainToInstance(ProcessExpense, {
            expense: plainToInstance(Expense, {
              description: isExpenseForm.expenseType.description,
              name: isExpenseForm.expenseType.name,
            }),
          }),
        })
        orderItem.recipients = [recipient]
        lastOrder.items.push(orderItem)
      }

      this.setFormDataExpense(lastOrder)
    }
  }

  setFormDataExpense (lastOrder) {
    this.formData.lastOrder = lastOrder
    this.formData.expenses = lastOrder
  }

  cancelPurchase () {
    const { formData } = this
    this.setBackup(null)
    const buyFile = formData?.buyFile?.length ? [...formData?.buyFile] : []
    const notarizedAuthorization = formData?.notarizedAuthorization?.length ? [...formData?.notarizedAuthorization] : []
    const legalReport = formData?.legalReport?.length ? [...formData?.legalReport] : []
    this.close([...buyFile, ...notarizedAuthorization, ...legalReport])
  }

  async getPurchaseInfo (id) {
    this.purchaseOrder = await this.fetchData({
      query: { name: 'fetch', model: 'PurchaseOrder', params: { id } },
      force: true,
    })

    this.stock = (await this.fetchData({
      query: { name: 'find', model: 'Stock' },
      filter: { enablement: { inspection: { negotiation: { purchase_order: { id: { _eq: id } } } } } },
      force: true,
    }))[0]
    this.formData.acquisitionType = this.purchaseOrder.acquisitionType
    this.document = await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: { id_process_record: { _eq: this.purchaseOrder.id } },
      force: true,
    })

    const { interveners } = this.document[0]

    const { agreedAmount, transferType, supportDocumentType } = this.purchaseOrder
    this.formData.price = agreedAmount
    this.formData.signers = interveners?.filter(intervener => intervener.field.name === 'sale_representative').map(intervener => intervener.person)
    this.formData.whoSigns = !this.formData.signers?.length
    this.fields.signers.items = interveners?.filter(intervener => intervener.field.name === 'sale_representative')?.map(intervener => intervener.person)
    this.formData.agreedTransfer = transferType
    this.formData.documentType = supportDocumentType
    this.stockView = (await this.fetchData({
      query: { name: 'find', model: 'StockViewDetails' },
      filter: { id_purchase: { _eq: id } },
      force: true,
    }))[0]

    await this.getNegotiationInfo(this.purchaseOrder.negotiation.id)
  }

  async getNegotiationInfo (id) {
    if (!id) return
    this.negotiation = await this.fetchData({
      query: { name: 'fetch', model: 'Negotiation', params: { id } },
      force: true,
    })

    this.deal = this.negotiation?.inspection?.appraisal?.deal
    this.purchaseOrder.negotiation = this.negotiation
    const versionYear = await this.fetchData({
      query: { name: 'find', model: 'VersionYear' },
      filter: { id: { _eq: this.negotiation?.inspection.appraisal.deal.auto.version.id } },
      force: true,
    })
    const year = this.negotiation.createdAt.year()
    const registration = versionYear?.[0]?.registrations?.find(registration => registration.year === year)
    this.negotiation.taxAssessment = registration?.permission

    this.formData.client = this.negotiation?.inspection?.appraisal?.deal?.lead?.client
    this.fields.client.items = [this.negotiation?.inspection?.appraisal?.deal?.lead?.client]
    this.formData.owners = this.negotiation?.inspection?.appraisal?.deal?.ownership?.owners?.map(owner => owner.person)
    this.fields.owners.items = this.formData.owners
    await this.setFilesData(this.negotiation)

    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'purchase_order' } } },
    })

    const fileInfoDocument = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'document' } } },
    })

    this.fileInfo = fileInfo

    this.setProperties(fileInfo, 'legal_report', 'legalReport')
    this.setProperties(fileInfo, 'notarized_authorization', 'notarizedAuthorization')
    this.setProperties(fileInfoDocument, 'consignment_contract', 'consignment')
    if (!this.purchaseOrder?.id) {
      this.formData.price = this.negotiation.agreedAmount
      this.purchaseOrder.agreedAmount = this.negotiation.agreedAmount
    }
  }

  async setFilesData (negotiation) {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'purchase_order' } },
    })

    const idProcess = process[0].id

    const processDocument = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'document' } },
    })

    this.idProcessDocument = processDocument[0].id
    const processNegotiation = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'negotiation' } },
    })

    const {
      report,
      document,
      notarized,
    } = await this.findAssociatedFiles(idProcess, this.purchaseOrder.id, this.purchaseOrder?.supportDocumentType?.name)
    this.formData.legalReport = report?.length ? report : []

    if (report?.[0]?.file?.sourceLink) {
      this.formData.linkLegalReport = report?.[0]?.file?.sourceLink
    }

    this.formData.notarizedAuthorization = notarized?.length ? notarized : []

    this.formData.buyFile = document?.length ? document : []

    if (document?.length) {
      this.formData.linkContract = document?.[0]?.file?.sourceLink
    }

    const {
      report: reportNegotiation,
    } = await this.findAssociatedFiles(processNegotiation[0].id, negotiation?.id, this.purchaseOrder?.supportDocumentType?.name)

    if (!report?.length) {
      this.formData.legalReport = reportNegotiation?.length ? [reportNegotiation[0]] : []
      if (reportNegotiation?.[0]?.file?.sourceLink || report?.[0]?.file?.uri) {
        this.formData.linkLegalReport = reportNegotiation?.[0]?.file?.sourceLink
      }
    }

    await this.findDocumentConsignment()
  }

  async findDocumentConsignment () {
    if (!this.purchaseOrder?.id) return

    const document = (await this.fetchData({
      query: { name: 'find', model: 'DocumentType' },
      filter: { _and: [{ name: { _eq: 'consignment_contract' } }, { process: { id: { _eq: this.idProcess } } }] },
    }))[0]

    const existDocument = (await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: {
        _and: [
          { id_document_type: { _eq: document?.id } },
          { id_process_record: { _eq: this.purchaseOrder.id } },
          { expiration_date: { _is_null: true } },
        ],
      },
      force: true,
    }))[0]
    this.consignmentHasDocument = await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: {
        _and: [
          { id_document_type: { _eq: document?.id } },
          { id_process_record: { _eq: this.purchaseOrder.id } },
        ],
      },
      force: true,
    })
    if (existDocument?.id) {
      this.existConsignment = existDocument
      const consignmentFile = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: { _and: [{ id_process_record: { _eq: existDocument?.id } }, { parameter: { process: { id: { _eq: this.idProcessDocument } } } }, { parameter: { name: { _eq: 'consignment_contract' } } }] },
        force: true,
      })

      this.formData.consignment = consignmentFile?.length ? consignmentFile : []
    }
  }

  saveBackup (item = null) {
    const { backup, formData, panels, negotiation } = this

    if (backup) {
      backup.purchaseForm = { ...formData, panels }
      backup.negotiation = negotiation
      backup.editPayment = item
      this.setBackup(backup)
    } else {
      this.setBackup({ purchaseForm: { ...formData, panels }, negotiation, editPayment: item })
    }
  }

  async setMetadata () {
    const { metadata } = this.getForm('PurchaseOrder', 'purchase_order')
    const { fields, form } = metadata as Form
    this.purchaseMetadata = metadata
    this.title = form.title
    this.fields.owners.properties = fields.owners.properties
    this.fields.price.properties = fields.price.properties
    this.fields.client.properties = fields.client.properties
    Object.assign(this.fields.buyFile.properties, fields.buyFile?.properties)
    this.fields.signers.properties = fields.signers.properties
    this.fields.expenses.properties = fields.expenses.properties
    Object.assign(this.fields.legalReport.properties, fields.legalReport?.properties)
    this.fields.documentType.properties = fields.documentType.properties
    this.fields.agreedTransfer.properties = fields.agreedTransfer.properties
    this.fields.linkLegalReport.properties = fields.linkLegalReport.properties
    this.fields.responsibleLetter.properties = fields.responsibleLetter.properties
    this.fields.linkContract.properties = fields.linkContract.properties
    Object.assign(this.fields.notarizedAuthorization.properties, fields.notarizedAuthorization?.properties)

    this.fields.agreedTransfer.items = await this.fetchData({
      query: { name: 'find', model: 'TransferType' },
    })
    this.fields.documentType.items = await this.fetchData({
      query: { name: 'find', model: 'SupportDocumentType' },
    })
    this.backupDocumentType = this.fields.documentType.items
    await this.setFilesFieldsData()
    const process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: {
        table_name: { _eq: 'purchase_order' },
      },
    }))[0]

    this.displayConsignment = process?.config?.displayConsignment
  }

  async setFilesFieldsData () {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'purchase_order' } },
    })

    const { id } = process[0] as Record<string, any>

    this.idProcess = id
  }

  async setDetails () {
    const { purchaseMetadata, purchaseOrder, negotiation } = this
    const lead = negotiation?.inspection?.appraisal?.deal?.lead

    if (lead?.id) {
      const deals = await this.fetchData({
        query: { name: 'findLite', model: 'Deal' },
        filter: { id_lead: { _eq: lead.id } },
        force: true,
      })
      purchaseOrder.dealSale = deals?.find(deal => deal.isSale)
      if (purchaseOrder?.dealSale?.stock) {
        purchaseOrder.dealSale.stock = await this.fetchData({
          query: { name: 'fetch', model: 'Stock', params: { id: purchaseOrder.dealSale.stock.id } },
          force: true,
        })
      }

      if (!this.autoAttribute?.id) {
        await this.findAutoAttributes(purchaseOrder.negotiation.inspection.deal)
      }
    }

    this.metadata = {
      data: purchaseOrder,
      metadata: purchaseMetadata,
    }
    this.showDetail = (Boolean(negotiation?.id))
  }

  async findAutoAttributes (deal) {
    this.dealAutoAttribute = (await this.fetchData({
      query: { name: 'find', model: 'DealAutoAttribute', order: { id: 'desc' } },
      filter: {
        _and: [
          { id_deal: { _eq: deal.id } },
          { process: { table_name: { _eq: 'inspection' } } },
          { component: { slug: { _eq: 'mileage' } } },
        ],
      },
      force: true,
    }))[0]
  }

  sectionValidation () {
    const {
      formData: {
        client,
        owners,
        legalReport,
        linkLegalReport,
        agreedTransfer,
        documentType,
        buyFile,
        linkContract,
        expenses,
      },
      isConsignmentSelected,
      isDigitalContract,
    } = this

    this.section.interviews = !client?.id || !owners?.length

    this.section.documents = !!(!legalReport?.length || !linkLegalReport || !agreedTransfer || (!documentType && !isConsignmentSelected) || (!buyFile?.length && !isConsignmentSelected) || (buyFile?.length && !linkContract && isDigitalContract))

    this.section.payment = !expenses?.payments?.length

    if (this.section.interviews) {
      const isInterview = this.panels.some(panel => panel === 0)
      if (!isInterview) {
        this.panels.push(0)
      }
    }

    if (this.section.documents) {
      const isDocument = this.panels.some(panel => panel === 1)
      if (!isDocument) {
        this.panels.push(1)
      }
    }

    if (this.section.payment) {
      const isPayment = this.panels.some(panel => panel === 2)
      if (!isPayment) {
        this.panels.push(2)
      }
    }

    return this.section.interviews || this.section.documents || this.section.payment
  }

  inValidPrice () {
    const { formData: { price, acquisitionType }, negotiation } = this

    if (acquisitionType?.name === 'consignment') {
      return false
    }

    const validation = !isValidNumber(price) || price <= 0 || parseToNumber(price) > parseToNumber(negotiation.negotiationResponse.value)

    if (validation) {
      this.priceErrorSend = 'El precio de compra no puede ser mayor al valor al autorizado de compra'
    }

    return validation
  }

  async send () {
    this.loadingForm = true

    const { purchaseOrder } = this

    if (!purchaseOrder?.id) {
      await this.createPurchase(this)
    } else {
      if (this.inValidPrice()) {
        this.loadingForm = false
        await this.setFieldError()
        return
      } else {
        await this.updatePurchase(this)
      }
    }

    await this.close()
  }

  get change () {
    const { formData, panels, section, purchaseOrder } = this

    return stringifySafe([formData, panels, section, purchaseOrder])
  }

  get displayConsignmentFile () {
    const { formData, purchaseOrder, loadingForm, existConsignment } = this
    if ((loadingForm && purchaseOrder?.id) || !existConsignment?.id) return

    const isNotConsignmentActive = purchaseOrder?.status?.name !== 'consignment_active'

    return formData.acquisitionType?.name === 'consignment' && (purchaseOrder?.id && (!purchaseOrder?.status?.isPending || isNotConsignmentActive))
  }

  async searchPerson ({ input, type }) {
    if (!input?.length || input?.length < 2) return null

    const { purchaseMetadata } = this

    if (!purchaseMetadata) return null
    const { fields } = purchaseMetadata as Form

    if (!fields?.client) return
    const query = updateNestedObject(fields.client.computed.queries.items.where, '_eq', this.replaceSpace(input), ['name_person_type'])

    const items = await this.findPersonSearch(query)

    if (type === 'client') {
      this.fields.client.items = items
    } else {
      this.fields.signers.items = items
    }
  }

  enablePayment (item) {
    return item.id && item.status && (item.status.name !== 'closed' && item.status.name !== 'pending_payment')
  }

  removePerson (person) {
    const { formData: { signers } } = this
    this.formData.signers = signers.filter(item => item.id !== person.id)
  }

  async editPerson (person) {
    this.saveBackup()

    await this.$router.push({ name: 'generic-person-nested', params: { model: 'Negotiation', uid: null, id: person.id } })
  }

  async goPerson (type) {
    this.saveBackup()

    const idPerson = type !== 'signer' ? this.formData[type]?.id : null
    await this.$router.push({ name: 'generic-person-nested', params: { model: 'Negotiation', uid: null, id: idPerson } })
  }

  get bindClient () {
    const { fields: { client } } = this

    if (!client) return {}

    return { ...client.properties, items: client.items }
  }

  get bindOwners () {
    const { fields: { owners } } = this

    if (!owners) return {}

    return { ...owners.properties, items: owners.items }
  }

  get bindSigners () {
    const { fields: { signers } } = this

    if (!signers) return {}

    return { ...signers.properties, items: signers.items }
  }

  get isDigitalContract () {
    const { formData: { documentType } } = this
    return documentType?.name === 'digital_contract'
  }

  get labelDocument () {
    const { formData: { documentType }, fileInfo } = this

    this.setProperties(fileInfo, documentType.name, 'buyFile')
    return `Comprobante de ${documentType?.description}`
  }

  setProperties (fileInfo, fileTypeName, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo.find(fileParameter => fileParameter.name === fileTypeName)
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.icon = info.fileType.icon
    }
  }

  loadingFile (flag) {
    this.isUploadingFile = flag
  }

  get totalExpenses () {
    const { formData: { price }, calculatedExpenses } = this

    if (!calculatedExpenses?.length || !price) return 0

    return calculatedExpenses.reduce((acc, payment) => acc + parseToNumber(payment?.amount), 0)
  }

  get isPurchasePriceOver () {
    const {
      formData: { price },
      negotiation,
    } = this
    const maxAmount = Math.max(parseToNumber(negotiation?.negotiationResponse?.value), negotiation?.negotiationResponseConsignment.value)
    if (maxAmount === 0) {
      return null
    }

    return parseToNumber(price) > parseToNumber(maxAmount)
  }

  get priceError () {
    const { isPurchasePriceOver, stockView, formData: { price } } = this

    if (isPurchasePriceOver === null) {
      const stockPrice = stockView?.stock?.prices[0]?.amount

      return parseToNumber(price) > parseToNumber(stockPrice) ? `El monto de compra no puede ser mayor al precio de venta ${fixPrice(stockPrice)}` : ''
    }

    return isPurchasePriceOver ? 'El monto de compra no puede ser mayor al autorizado' : ''
  }

  get isPurchase () {
    const { formData: { price }, negotiation, acquisitionTypes, consignmentHasDocument } = this

    if (!acquisitionTypes?.length) return null

    if (!negotiation?.negotiationResponse?.value) return false

    if (consignmentHasDocument?.length) {
      return false
    }

    return parseToNumber(price) <= parseToNumber(negotiation.negotiationResponse.value)
  }

  get isConsignment () {
    const { formData } = this

    return formData?.acquisitionType?.name === 'consignment'
  }

  get purchaseIsApproved () {
    const { purchaseOrder } = this

    return purchaseOrder?.status?.isApproved
  }

  get expenseError () {
    const { formData: { price, expenses }, isExpenseFull, totalExpenses, isOverPrice } = this

    if (isOverPrice) return 'El gasto no puede ser mayor al monto de la compra'

    if (!expenses?.payments?.length) return ''
    return !isExpenseFull ? '' : `Se debe cubrir el monto de compra ${fixPrice(parseToNumber(price) - parseToNumber(totalExpenses))}`
  }

  get isExpenseFull () {
    const { formData: { price }, totalExpenses } = this

    if (!totalExpenses) return false

    return parseToNumber(totalExpenses) < parseToNumber(price)
  }

  get isOverPrice () {
    const { formData: { price }, totalExpenses } = this

    if (!totalExpenses) return false

    return parseToNumber(totalExpenses) > parseToNumber(price)
  }

  get needNotarizedAuthorization () {
    const { formData: { owners, signers, whoSigns } } = this

    if (whoSigns) {
      this.formData.notarizedAuthorization = []
      this.formData.signers = []
      return false
    }

    if (!signers?.length) return false

    return signers.some(signer => !owners?.some(owner => signer?.id === owner?.id))
  }

  async goToOrder (item = null) {
    if (this.backup) {
      this.backup.expenseForm = null
    }
    this.saveBackup('person' in item && item)
    const id = this.purchaseOrder?.id?.toString() || 'create'

    await this.$router.push({
      name: 'generic-expenses',
      params: { parentModel: 'PurchaseOrder', parentId: id, uid: 'create' },
    })
  }

  async editExpense (item) {
    if (this.backup) {
      this.backup.expenseForm = null
    }
    if (!item?.id) {
      await this.goToOrder(item)
      return
    }
    this.saveBackup(item)
    const id = item?.id?.toString()

    await this.$router.push({
      name: 'generic-expenses',
      params: { parentModel: null, parentId: null, uid: id },
    })
  }

  confirmRemoveExpense (item) {
    this.purchase = {
      open: true,
      title: '¿Está seguro que desea eliminar el gasto?',
      item,
    }
  }

  async removeExpense (item) {
    for (let i = 0; i < this.formData.expenses.items.length; i++) {
      const recipients = this.formData.expenses?.items[i].recipients
      const pos = recipients.findIndex(recipient => this.findLocalPos(recipient, {
        beneficiary: item.person,
        paymentType: item.paymentType,
        account: item.account,
        type: item.type,
        amount: item.amount,
      }))
      if (pos !== -1) {
        const recipient = this.formData.expenses.items[i].recipients[pos]
        const itemType = this.formData.expenses.items[i]

        const posPayment = recipient?.payments?.findIndex(_ => this.findLocalPayment(_, recipient.person, itemType, item))

        if (posPayment !== -1) {
          if (this.formData.expenses.items[i].recipients?.length > 1) {
            this.purchaseOrder.paymentOrder.items[i].recipients[pos].payments.splice(posPayment, 1)
            this.formData.expenses.items[i].recipients[pos]?.payments.splice(posPayment, 1)
          } else {
            this.purchaseOrder.paymentOrder.items.splice(i, 1)
            this.formData.expenses.items.splice(i, 1)
          }
        }
      }
      if (!this.formData.lastOrder?.items?.length) {
        this.formData.lastOrder = null
      }

      this.purchase.open = false
    }
    await this.setDetails()
  }

  @Watch('formData.buyFile', { immediate: true }) onBuyChange (val) {
    const { isDigitalContract, backup } = this
    if (isDigitalContract && val?.length) {
      this.formData.linkContract = val?.[0]?.sourceLink || val?.[0]?.file?.sourceLink || backup?.purchaseForm?.linkContract
    }
    if (!val?.length && !backup?.purchaseForm?.linkContract) {
      this.formData.linkContract = null
    }
  }

  @Watch('formData.legalReport', { immediate: true }) onLegalReportChange (val) {
    const { backup } = this
    if (val?.length) {
      this.formData.linkLegalReport = val?.[0]?.sourceLink || val?.[0]?.file?.sourceLink || backup?.purchaseForm?.linkLegalReport
    }
    if (!val?.length && !backup?.purchaseForm?.linkLegalReport) {
      this.formData.linkLegalReport = null
    }
  }

  @Watch('formData.signers', { immediate: true, deep: true })
  onSignersChange (val) {
    if (!val?.length) {
      this.signerError = 'El representante es obligatorio'
      return
    }
    const errors = nameAlert(val) || []
    val?.forEach(signer => {
      if (!signer?.uid) {
        errors.push(`El representante ${signer.name} no tiene rut registrado`)
      }
    })

    this.signerError = errors.join(', ')
  }

  @Watch('formData.owners', { immediate: true, deep: true })
  onOwnersChange (val) {
    if (!val?.length) return

    this.validatePersonInfo(val, 'ownerError')
  }

  @Watch('formData.client', { immediate: true, deep: true })
  onClientChange (val) {
    if (!val?.length) return

    const errors = nameAlert(val)

    if (!val?.uid) {
      errors.push(`El cliente ${val[0].name} no tiene rut registrado`)
    }

    this.clientError = errors.join(', ')
  }

  @Watch('isPurchase', { immediate: true })
  onIsPurchaseChange (val) {
    if (val === null || (this.loadingForm && this.purchaseOrder?.id)) return
    if (this.purchaseOrder?.id && this.formData.acquisitionType?.name === 'purchase') {
      return
    }

    this.formData.acquisitionType = this.acquisitionTypes.find(type => type.name === (!val ? 'consignment' : 'purchase'))
    this.formData.agreedTransfer = !val ? this.fields.agreedTransfer.items.find(type => type.name === 'open') : null
  }

  @Watch('formData.acquisitionType', { immediate: true, deep: true })
  onAcquisitionTypeChange (val) {
    if (val?.name === 'consignment') {
      this.fields.documentType.items = this.fields.documentType.items.filter(item => ['digital_contract', 'physical_contract'].includes(item.name))
      this.formData.agreedTransfer = this.fields.agreedTransfer.items.find(type => type.name === 'open')
    } else {
      this.fields.documentType.items = this.backupDocumentType
    }
  }

  async validatePersonInfo (persons, field) {
    let error = false
    for (const person of persons) {
      const address = await this.fetchData({
        query: { name: 'find', model: 'PersonAddress' },
        filter: { _and: [{ id_person: { _eq: person.id } }, { active: { _eq: true } }] },
        force: true,
      })

      const errors = nameAlert(person)
      if (!person?.uid && !error) {
        error = true
        errors.unshift(`Sin RUT asociado`)
        this[field] = `${person.fullName} ${errors.join(', ')}`
      }

      if (!address?.length && !error) {
        error = true
        errors.unshift(`Sin dirección asociada`)
        this[field] = `${person.fullName} ${errors.join(', ')}`
      }

      if (errors?.length && !error) {
        error = true
        this[field] = `${person.fullName} ${errors.join(', ')}`
      }
    }

    if (!error) {
      this[field] = ''
    }
  }

  get calculatedExpenses () {
    const { formData: { expenses }, payments } = this

    const pays = expenses?.payments?.filter(payment => !payments.map(pay => pay.id)?.includes(payment.id))

    return pays || []
  }

  get signers () {
    const { formData: { signers, owners, whoSigns } } = this

    if (whoSigns === false) {
      return signers?.length ? signers : []
    }

    if (!signers?.length) return owners || []

    return signers
  }

  get disabledApproved () {
    const { purchaseOrder } = this

    const approved = purchaseOrder?.status?.isApproved
    const toConfirm = purchaseOrder?.status?.isToConfirm

    return approved || toConfirm
  }

  get isPickupDisabled () {
    const { purchaseOrder } = this

    return purchaseOrder?.status?.name.includes('pickup')
  }

  get disabledPurchase () {
    const { purchaseOrder, disabledApproved } = this

    if (purchaseOrder?.closingReason?.id) {
      return true
    }

    return purchaseOrder?.status?.isActive || disabledApproved
  }

  get displayAcquisitionTypes () {
    const { acquisitionTypes } = this

    return acquisitionTypes.length !== 1
  }

  @Watch('formData.price', { immediate: true })
  onPriceChange (val) {
    this.priceErrorSend = ''
    this.purchaseOrder.agreedAmount = val
  }

  async confirmNegotiation () {
    this.negotiationAlert.flag = false
    this.negotiationAlert.title = ''
    this.negotiationAlert.subtitle = ''
    await this.send()
  }

  async updateConsignmentInfo () {
    const signers = this.$refs.fieldSigners && !this.$refs.fieldSigners?.$refs.form.validate() && this.formData.whoSigns === false
    const client = this.$refs.fieldClient && !this.$refs.fieldClient.$refs.form.validate()
    const errorsPresent = Boolean(this.expenseError?.length) || Boolean(this.priceError?.length) || Boolean(this.signerError?.length && this.formData.whoSigns === false) || Boolean(this.ownerError?.length)
    const signerEmpty = !this.signers?.length

    if (client || signers || !this.$refs.form.validate() || this.sectionValidation() || errorsPresent || signerEmpty) {
      await this.setFieldError()
      return
    }

    const { purchaseOrder, paymentOrder } = this
    this.loadingForm = true
    await this.updateConsignment(this)
    await this.updateInterveners(this)

    let payments

    if (paymentOrder?.length) {
      payments = await this.insertPaymentRecipient(this)
    } else {
      payments = await this.insertPaymentOrder(this, purchaseOrder.id)
    }
    const document = (await this.fetchData({
      query: { name: 'find', model: 'DocumentType' },
      filter: { _and: [{ name: { _eq: 'consignment_contract' } }, { process: { id: { _eq: this.idProcess } } }] },
    }))[0]

    const existDocument = (await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: {
        _and: [
          { id_document_type: { _eq: document?.id } },
          { id_process_record: { _eq: this.purchaseOrder.id } },
          { expiration_date: { _is_null: true } },
        ],
      },
      force: true,
    }))[0]

    if (existDocument?.id) {
      await this.insertPurchaseOrderUpdateFile(this, purchaseOrder.id, existDocument?.id)
    } else {
      const newDocument = await this.insertDocument(payments, this, purchaseOrder.id, document, true)
      await this.insertPurchaseOrderUpdateFile(this, purchaseOrder.id, newDocument?.id)
    }

    await this.close()
  }

  async openAlertNegotiation () {
    const signers = this.$refs.fieldSigners && !this.$refs.fieldSigners?.$refs.form.validate() && this.formData.whoSigns === false
    const client = this.$refs.fieldClient && !this.$refs.fieldClient.$refs.form.validate()
    const errorsPresent = Boolean(this.expenseError?.length) || Boolean(this.priceError?.length) || Boolean(this.signerError?.length && this.formData.whoSigns === false) || Boolean(this.ownerError?.length)
    const signerEmpty = !this.signers?.length

    if (client || signers || !this.$refs.form.validate() || this.sectionValidation() || errorsPresent || signerEmpty) {
      await this.setFieldError()
      return
    }

    const alertStatus = !this.purchaseOrder?.id

    if (alertStatus) {
      this.negotiationAlert.title = `Está creando una ${this.formData.acquisitionType?.description}`
      this.negotiationAlert.subtitle = '¿Estás seguro que deseas continuar?'
      this.negotiationAlert.flag = true
    } else {
      await this.send()
    }
  }

  cancelNegotiationSend () {
    this.formData.price = null
    this.negotiationAlert.flag = false
    this.negotiationAlert.title = ''
    this.negotiationAlert.subtitle = ''
  }

  cancelConsignment () {
    this.close()
  }

  cancelContinue () {
    this.openConsignmentAlert.flag = false
    this.confirmPaymentAnulled.flag = false
  }

  confirmContinue () {
    this.openConsignmentAlert.flag = false
    this.confirmPaymentAnulled.flag = true
  }

  async confirmContinuePaymentAnulled () {
    this.loadingForm = true
    this.confirmPaymentAnulled.flag = false
    await this.updatePaymentOrder(this)
    await this.pushData({
      model: 'PurchaseOrder',
      fields: {
        id: this.purchaseOrder.id,
        id_process_status: this.purchaseOrderStatus?.renewActive?.[0]?.id,
      },
    })

    const document = (await this.fetchData({
      query: { name: 'find', model: 'DocumentType' },
      filter: { _and: [{ name: { _eq: 'consignment_contract' } }, { process: { id: { _eq: this.idProcess } } }] },
    }))[0]

    const existDocument = (await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: {
        _and: [
          { id_document_type: { _eq: document?.id } },
          { id_process_record: { _eq: this.purchaseOrder.id } },
          { expiration_date: { _is_null: true } },
        ],
      },
      force: true,
    }))[0]

    if (existDocument?.id) {
      await this.pushData({
        model: 'Document',
        fields: {
          id: existDocument.id,
          expiration_date: dayjs().format('YYYY-MM-DD'),
        },
      })
    }
    window.location.reload()
  }

  get purchaseIsConsignment () {
    const { purchaseOrder, formData, stock } = this

    if (!purchaseOrder?.id || stock?.status?.isSold || stock?.status?.name === 'retired') return
    const status = ['consignment_active', 'consignment_to_update']
    const isConsignment = purchaseOrder?.status?.isApproved || status.includes(purchaseOrder?.status?.name)

    return purchaseOrder?.id && formData?.acquisitionType?.name === 'consignment' && isConsignment
  }

  get isConsignmentActive () {
    const { purchaseOrder } = this

    const status = ['to_confirm', 'consignment_to_confirm', 'approved']
    return status.includes(purchaseOrder?.status?.name)
  }

  get isContract () {
    const { formData } = this

    return formData?.buyFile?.length
  }
  }
